import { useCallback, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';

import {
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-common-data-provider';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { Select as MagritteSelect } from '@hh.ru/magritte-ui-select';
import ConversionNumber from 'bloko/blocks/conversion';
import Select, { Option } from 'bloko/blocks/select';
import getConversionType from 'bloko/common/getConversionType';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

const DELETE_INACTIVE_TIME_YEARS = [3, 4, 5];

export const DELETE_INACTIVE_TIME_DEFAULT = 'YEARS_5';

const TrlKeys = {
    title: 'accountDeleteInactiveTime.title',
    in: 'accountDeleteInactiveTime.in',
    description: 'accountDeleteInactiveTime.description',
    years: {
        one: 'abstract.years.one',
        some: 'abstract.years.some',
        many: 'abstract.years.many',
    },
};

const AccountDeleteInactiveSelect: TranslatedComponent<FieldInputProps<string | undefined>> = ({
    trls,
    value,
    ...input
}) => {
    const isMagritte = useMagritte();
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { isApplicantRenewedAuthExp } = useApplicantRenewedAuthExp();
    const { isGtM, isGtS } = useBreakpoint();

    const optionLabel = useCallback(
        (value: number) =>
            formatToReactComponent(trls[TrlKeys.in], {
                '{0}': (
                    <ConversionNumber
                        many={trls[TrlKeys.years.many]}
                        some={trls[TrlKeys.years.some]}
                        one={trls[TrlKeys.years.one]}
                        value={value}
                    />
                ),
            }),
        [trls]
    );

    const MAGRITTE_DELETE_INACTIVE_TIME_YEARS: StaticDataFetcherItem[] = useMemo(
        () =>
            DELETE_INACTIVE_TIME_YEARS.map((item) => ({
                value: `YEARS_${item}`,
                text: format(trls[TrlKeys.in], {
                    '{0}': `${item} ${getConversionType({
                        one: trls[TrlKeys.years.one],
                        some: trls[TrlKeys.years.some],
                        many: trls[TrlKeys.years.many],
                        value: item,
                    })}`,
                }),
            })),
        [trls]
    );

    const dataProvider = useStaticDataProvider(MAGRITTE_DELETE_INACTIVE_TIME_YEARS);

    if (isMagritte) {
        return (
            <MagritteSelect
                {...input}
                type="radio"
                multiple={false}
                triggerProps={{
                    size: 'medium',
                    label: trls[TrlKeys.title],
                    stretched: true,
                    ...((isAnonymousMagritteExp || isApplicantRenewedAuthExp) && { stretched: true }),
                    ...(isAnonymousMagritteExp && isGtS && { maxWidth: 358 }),
                    ...(isAnonymousMagritteExp && isGtM && { maxWidth: 364 }),
                }}
                dataProvider={dataProvider}
                bottomSheetHeight="content"
                value={findStaticDataFetcherItemByValue(value ?? '', MAGRITTE_DELETE_INACTIVE_TIME_YEARS)}
                onChange={(newItem) => input.onChange(newItem.value)}
            />
        );
    }

    return (
        <Select {...input} value={value}>
            {DELETE_INACTIVE_TIME_YEARS.map((value) => {
                return (
                    <Option value={`YEARS_${value}`} key={value}>
                        {optionLabel(value)}
                    </Option>
                );
            })}
        </Select>
    );
};

export default translation(AccountDeleteInactiveSelect);
